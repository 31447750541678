import React, { useEffect, useState } from 'react';
import { Images } from '../../utils/Images';
import {isLoggedIn, APIHOST, loadError, loadSuccess, updateloginStatus, setToken, setCandy, setUser} from '../../utils/gaia'
import {validEmail} from '../../utils/utility'
import { useNavigate } from 'react-router-dom';
import Axios from 'axios';
 
const Login = (props) => {
    const navigate = useNavigate();
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [loading, setLoading] = useState(false);

    useEffect(()=>{
        if(isLoggedIn()){navigate("/app")}
    })

    const loginUser = (evt) =>{
        evt.preventDefault();
        if(email === "" || password === ""){
            loadError("All fields are required")
            return;
        }
        setLoading(true)
        Axios.post(`${APIHOST}staff/login`, {
            email: email,
            password: password,
        }).then(function(res){
            let {data} = res;
            updateloginStatus(true);
            setToken(data.stamp);
            setCandy(data.session);
            setUser(email)
            setLoading(false)
            loadSuccess(data.msg)
            setTimeout(()=>{  window.location.href =  "/app" },1500)
        }).catch(function(er){
            setLoading(false)
            if(typeof(er.response) !== "undefined"){
                if(er.response.status == 404 || er.response.status == 500){
                    loadError(er.response.data.error ?? er.response.data.message)
                }else{
                    loadError("A networ error occured.")
                }
            }else{
                loadError(er.message)
            }
        })
    }

    return (
      <>
        <section className="common-bg">
            <div className='login-form centered'>
                <form onSubmit={loginUser}>
                    <div className="form-group mb-3">
                        <label className='text-black'>Email address</label>
                        <input type="email" value={email} className="form-control" placeholder="Enter email" onChange={(e)=>setEmail(e.target.value)} />
                    </div>
                    <div className="form-group mb-3">
                        <label className='text-black'>Password</label>
                        <input type="password" value={password} className="form-control" placeholder="Password" onChange={(e)=>setPassword(e.target.value)} />
                    </div>
                    <button type="submit" className="btn d-block btn-primary" disabled={!loading ? false : true}>{loading ? <i className='fa fa-spinner fa-spin'></i> : "Submit"}</button>
                </form>
            </div>
        </section>
      </>
    );
}

export default Login;
