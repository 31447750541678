import React from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import routes from '../../utils/routes';
import { Images } from '../../utils/Images';
import { destroySession } from '../../utils/gaia';
 
const Main = (props) => {
    const navigate = useNavigate()
    const logout = () =>{
      destroySession();
      navigate("/")
    }
    return (
      <>
        <section className="common-bg">
          <Routes>
            {routes.map((route, idx) => (
              <Route exact path={route.path} key={idx} name="Landing Page" element={<route.element />} />
            ))}
          </Routes>          
        </section>
        <a className='logout' onClick={logout} ></a>
      </>
    );
}

export default Main;
